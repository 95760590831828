import React from 'react';
import ErrorIcon from './ui-icons/error';
import WarningIcon from './ui-icons/warning';
import BellIcon from './ui-icons/bell';
import CheckIcon from './ui-icons/check';
import SourceControlIcon from './ui-icons/source-control';
import { ReactJS } from './ui-icons/skills';
import { ChevronsRightLeft } from 'lucide-react';

const Bottombar = () => {
  const sectionCSS = "flex items-center mr-2 cursor-pointer hover:bg-bottombar-hover-bg max-sm:text-xs px-1.5 h-full font-source-sans-pro"
  return (
    <footer className=" border-t bg border-bottombar-border text-button-text flex items-center justify-between text-sm max-sm:text-xs">
      <div className="flex items-center">
        <div className='bg-blue-500 min-w-48 m-auto'><ChevronsRightLeft className='w-8 p-1' /></div>
        <div
          className={sectionCSS}
        >
          <SourceControlIcon className="mr-1" />
          <p>v2</p>
        </div>
        <div className={sectionCSS}>
          <ErrorIcon className="mr-1" />
          <p className="text-white-500">0</p>&nbsp;&nbsp;
          <WarningIcon className="mr-1" />
          <p>0</p>
        </div>
      </div>
      <div className="flex items-center h-full">
        <div className={`${sectionCSS} hidden xs:flex`}>
          <ReactJS className="w-4 mr-1" />
          <p>Powered by React</p>
        </div>
        <div className={sectionCSS}>
          <CheckIcon className="mr-1" />
          <p>Prettier</p>
        </div>
        <div className={sectionCSS}>
          <BellIcon />
        </div>
      </div>
    </footer>
  );
};

export default Bottombar;
