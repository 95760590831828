import AppContext, { Section } from '../../contexts/appContext';
import  * as styles from '../../styles/Tab.module.css';
import React, { useContext } from 'react';

const Tab = (props: { icon:any, filename:string, path : string, section: Section }) => {
  const context = useContext(AppContext);

  return (
    <div onClick={() => {
      context?.setActiveSection(props.section);
    }} className='cursor-pointer'>
      <div
        className={`${styles.tab} ${context?.activeSection === props.section && styles.active}`}
      >
        <img src={props.icon} alt={props.filename} height={18} width={18} />
        <p>{props.filename}</p>
      </div>
    </div>
  );
};

export default Tab;
