import Titlebar from './titlebar';
import React from 'react';
import Sidebar from './sidebar';
import Explorer from './explorer';
import Bottombar from './bottombar';
import Tabsbar from './tabsbar';
import * as styles from '../styles/Layout.module.css';
import AppContextProvider from '../contexts/appContextProvider';
import "../styles/themes.css";

const Layout = (props: { children: any }) => {
  return (
    <AppContextProvider  >
      <Titlebar />
      <div className={styles.main}>
        <Sidebar />
        <Explorer />
        <div style={{ overflowY: 'hidden', width: '100%' }}>
          <Tabsbar />
          <main className={`${styles.content} xs:p-0 relative`} id='scrollingParent'>
            {props.children}
          </main>
        </div>
      </div>
      <Bottombar />
    </AppContextProvider>
  );
};

export default Layout;
