import React, { useContext } from 'react';
import { useState } from 'react';
import ChevronRight from './ui-icons/chevron-right';
import * as styles from '../styles/Explorer.module.css';
import reactIcon from '../images/icons/react_icon.svg';
import markdownIcon from '../images/icons/markdown_icon.svg';
import htmlIcon from '../images/icons/html_icon.svg';
import gitIcon from '../images/icons/git_icon.svg';
import goIcon from '../images/icons/go_icon.svg';
import jsIcon from '../images/icons/js_icon.svg';
import cssIcon from '../images/icons/css_icon.svg';
import AppContext, { Section } from '../contexts/appContext';

const explorerItems = [
  {
    name: 'Home.tsx',
    icon: reactIcon,
    section: Section.home
  },
  {
    name: 'ABOUTME.md',
    icon: markdownIcon,
    section: Section.about
  },
  {
    name: 'experiences.html',
    icon: htmlIcon,
    section: Section.experiences
  },
  {
    name: '.gitprojects',
    icon: gitIcon,
    section: Section.projects
  },
  {
    name: 'education.js',
    icon: jsIcon,
    section: Section.education
  },
  {
    name: 'testimonials.go',
    icon: goIcon,
    section: Section.testimonials
  },
  {
    name: 'contact.css',
    icon: cssIcon,
    section: Section.contact
  },
];

const Explorer = () => {
  const [portfolioOpen, setPortfolioOpen] = useState(true);
  const context = useContext(AppContext);

  return (
    <div className={styles.explorer}>
      <p className={styles.title}>Explorer</p>
      <div>
        <input
          type="checkbox"
          className={styles.checkbox}
          id="portfolio-checkbox"
          checked={portfolioOpen}
          onChange={() => setPortfolioOpen(!portfolioOpen)}
        />
        <label htmlFor="portfolio-checkbox" className={styles.heading}>
          <ChevronRight
            className={styles.chevron}
            style={portfolioOpen ? { transform: 'rotate(90deg)' } : {}}
          />
          Portfolio
        </label>
        <div
          className={styles.files}
          style={portfolioOpen ? { display: 'block' } : { display: 'none' }}
        >
          {explorerItems.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => (context?.setActiveSection(item.section))}
                className={'cursor-pointer ' + IsMatch(item.section, context?.activeSection || Section.home)}
              >
                <div className={styles.file}>
                  <img
                    src={item.icon}
                    alt={item.name}
                    color='white'
                    height={18}
                    width={18}
                  />{' '}
                  <p>{item.name}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

const IsMatch = (section: Section, activeSection: Section): string => {
  if (section == activeSection) {
    return 'brightness-125 bg-gray-700';
  }
  return ''
}


export default Explorer;
