import Tab  from './ui/tab';
import  * as styles from '../styles/Tabsbar.module.css';
import React from 'react';
import reactIcon from '../images/icons/react_icon.svg';
import markdownIcon from '../images/icons/markdown_icon.svg';
import htmlIcon from '../images/icons/html_icon.svg';
import gitIcon from '../images/icons/git_icon.svg';
import jsIcon from '../images/icons/js_icon.svg';
import cssIcon from '../images/icons/css_icon.svg';
import goIcon from '../images/icons/go_icon.svg';

import { Section } from '../contexts/appContext';

const Tabsbar = () => {
  return (
    <div className={`${styles.tabs} hidden md:flex`}>
      <Tab icon={reactIcon} filename="Home.tsx" path="/" section={Section.home} />
      <Tab icon={markdownIcon}filename="ABOUTME.md" path="/about" section={Section.about}/>
      <Tab icon={htmlIcon} filename="experiences.html" path="/experiences" section={Section.experiences}/>
      <Tab icon={gitIcon} filename=".gitprojects" path="/projects" section={Section.projects}/>
      <Tab icon={jsIcon} filename="education.js" path="/education"section={Section.education} />
      <Tab icon={goIcon} filename="testimonials.go" path="/testimonials" section={Section.testimonials}/>
      <Tab icon={cssIcon} filename="contact.css" path="/contact" section={Section.contact}/>
    </div>
  );
};

export default Tabsbar;
