import React, { useState } from 'react';
import AppContext, { Section } from './appContext';

const AppContextProvider = (props: { children: React.ReactNode }) => {
  const [language, setLanguage] = useState('en');
  const [ref, setRef] = useState(null);
  const [activeSection, setActiveSection] = useState<Section>(Section.home);

  const value = {
    language,
    ref,
    setRef,
    setLanguage,
    activeSection,
    setActiveSection,
  };

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
