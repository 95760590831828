import React, { useContext } from 'react';
import FilesIcon from './ui-icons/files';
import AccountIcon from './ui-icons/account';
import { MessagesSquare } from 'lucide-react';
import SettingsIcon from './ui-icons/settings';
import * as styles from '../styles/Sidebar.module.css';
import AppContext, { Section } from '../contexts/appContext';
import { Code2, Github, GraduationCap, Search, Send } from 'lucide-react';

const sidebarTopItems = [
  {
    Icon: FilesIcon,
    section: Section.home
  },
  {
    Icon: Search,
    section: Section.about
  },
  {
    Icon: Code2,
    section: Section.experiences
  },
  {
    Icon: Github,
    section: Section.projects
  },
  {
    Icon: GraduationCap,
    section: Section.education
  },
  {
    Icon: MessagesSquare,
    section: Section.testimonials
  },
  {
    Icon: Send,
    section: Section.contact
  },
];

const sidebarBottomItems = [
  {
    Icon: AccountIcon,
    path: '/about',
    section: Section.about
  },
  {
    Icon: SettingsIcon,
    path: '/settings',
    section: Section.settings
  },
];

const Sidebar = () => {
  const context = useContext(AppContext);
  return (
    <aside className={styles.sidebar}>
      <div >
        {sidebarTopItems.map(({ Icon, section }, index) => {
          const color = context?.activeSection === section ? 'text-white' : '';
          return (
            <div key={index} onClick={() => {
              context?.setActiveSection(section);
            }} className='cursor-pointer'>
              <div
                className={`${styles.iconContainer} ${context?.activeSection === section && styles.active
                  }`}
              >
                <Icon
                  className={`h-10 w-10 px-2 m-auto lg:px-3 md:h-12 md:w-12 ${section === Section.home && context?.activeSection=== Section.home ? ' fill-white' : color }`}
                  color={context?.activeSection === section && context?.activeSection != Section.home ? 'rgb(225, 228, 232)' : 'rgb(106, 115, 125)'}
                />
              </div>
            </div>
          )
        }
        )}
      </div>
      <div >
        {sidebarBottomItems.map(({ Icon, path, section }, index) => (
          <div key={index} onClick={() => {
            context?.setActiveSection(section);
          }} className='cursor-pointer'>
            <div key={index} className={styles.iconContainer}>
              <Icon
                fill={
                  context?.activeSection === section
                    ? 'rgb(225, 228, 232)'
                    : 'rgb(106, 115, 125)'
                }
                className={"h-10 w-10 px-2 m-auto lg:px-3 md:h-12 md:w-12"}
              />
            </div>

          </div>
        ))}
      </div>
    </aside>
  );
};

export default Sidebar;
