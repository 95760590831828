import { createContext } from 'react';

export enum Section {
    about = 'about',
    home = 'home',
    experiences = 'experiences',
    projects = 'projects',
    education = 'education',
    contact = 'contact',
    settings = 'settings',
    testimonials = 'testimonials'
}

type AppContextType = {
    language: string;
    ref: any;
    setRef: (ref: any) => void;
    setLanguage: (language: string) => void;
    activeSection: Section;
    setActiveSection: (section: Section) => void;
}

export const AppContext = createContext<AppContextType | null>({
    language: 'en',
    ref: null,
    setRef: () => {},
    activeSection: Section.home,
    setLanguage: () => {},
    setActiveSection: () => {},
});



export default AppContext;